import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-arabe-pour-adultes',
  templateUrl: './arabe-pour-adultes.component.html',
  styleUrls: ['./arabe-pour-adultes.component.css']
})
export class ArabePourAdultesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
