<section class="site-section">
        <div class="container">
            <div class="col-lg-8 offset-lg-2 col-sm-12">
                
                <p><img class="img-fluid" src="../assets/pages/arabe_adulte.svg" alt="Image placeholder"></p>
                
                <h3>FRANÇAIS FLE</h3>
                <p class="text-justify ">Vous êtes débutants en français et souhaitez progresser rapidement ? METHODE PLUS vous propose des cours de FLE.</p>                 
                <p class="text-justify mb-1">Les cours de FLE (Français Langue Étrangère) s’adressent à tout étranger, étudiant ou pas, quel que soit son niveau d’étude, qui souhaite apprendre le français
                        ou se perfectionner.</p>
              
                <p class="mb-2">Pour tous renseignements, il vous suffit de remplir ce formulaire de préinscription : </p>
                <p><a class="btn btn-md btn-primary" href="https://docs.google.com/forms/d/1XUWvE4Odu-bcq7jIoOZWt3gYWvGW-sCUcmeafq190Z4/edit" target="_blank">Cliquer ici </a></p>
                      
            </div>
          
        </div>
    
    
    </section>