
<section class="site-section">
	<div class="container">
		<div class="col-lg-8 offset-lg-2 col-sm-12">
			
				

			<p><img class="img-fluid" src="../assets/pages/arabe_adulte.svg" alt="Image placeholder"></p>
			
			<h3>Qui sommes nous</h3>
			<p class="text-justify mb-2"><strong>METHODE PLUS</strong> est une association à but non lucratif basé à Nantes. C’est un centre spécialisé dans l’enseignement des langues
				vivantes et du soutien scolaire.
			<p class="text-justify mb-2"> Nous sommes des professionnels de l’enseignement à la recherche du meilleur pour nos élèves.</p>
			<p class="text-justify ">Notre principale préoccupation est la réussite et le bien-être de nos élèves.</p>

			
		</div>
	</div>


</section>