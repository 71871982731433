<section class="site-section">
        <div class="container">
            <div class="col-lg-8 offset-lg-2 col-sm-12">
                
                <p><img class="img-fluid" src="../assets/pages/arabe_adulte.svg" alt="Image placeholder"></p>
                
                <h3>Soutien scolaire</h3>
                <p class="text-justify">METHODE PLUS vous propose un accompagnement scolaire
                </p>
                        

                <p class="mb-1">Les cours auront lieu chaque week-end et pendant les vacances scolaires.</p>
                <p class="mb-1">Le début des cours est prévu pour le premier week-end du mois d'octobre. </p>
                <p class="mb-1">  L’inscription définitive sera validée à la suite d’un entretien avec l’équipe pédagogique et le paiement de la cotisation. </p>
                <p class="mb-1"> L'ouverture des classes est conditionnée à un nombre minimum d'inscrits par classe.</p>
                
                          
                <p class="mb-2">Pour tous renseignements, il vous suffit de remplir ce formulaire de préinscription :</p>
                <p> <a class="btn btn-md btn-primary" href="https://docs.google.com/forms/d/1CLhKNTaU4gkd6pevsKnxQhZKeB2FV1l_pb4-zqp1L1U/edit" target="_blank"> Cliquer ici </a></p>
               


                
            </div>
           
        </div>
    
    
    </section>