import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sciences-islam',
  templateUrl: './sciences-islam.component.html',
  styleUrls: ['./sciences-islam.component.css']
})
export class SciencesIslamComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
