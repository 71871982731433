<section class="site-section">
        <div class="container">
            <div class="col-lg-8 offset-lg-2 col-sm-12 col-xs-12">
                
                <p><img class="img-fluid" src="../assets/pages/arabe_adulte.svg" alt="Image placeholder"></p>
                
                <h3>Anglais</h3>
                <p class="text-justify ">Vous avez besoin de vous perfectionner en anglais pour des raisons professionnelles ou personnelles ?</p>                 
                <p class="text-justify mb-1">METHODE PLUS vous propose plusieurs formules : </p>
                <ul class="mb-4">
                        <li>Des cours à l’année pour progresser d’octobre à juin : 1h30 de cours d'anglais par semaine excepté pendant les vacances scolaires</li>
                        <li>Des stages intensifs pendant les vacances scolaires en petits groupes de 3 à 12 personnes du même niveau (basés sur le CECRL) dans des locaux
                                modernes et spacieux et avec des méthodes pédagogiques diverses et variées.</li>				                       
                </ul>
              
                <p class="mb-2">Pour tous renseignements, il vous suffit de remplir ce formulaire de préinscription.</p>
                <p><a class="btn btn-md btn-primary" href="https://docs.google.com/forms/d/1VRKEcTlsaCweMVwc-ceFNAKop8mJZo8fyI1JFLgmAgA/edit" target="_blank"> Cliquer ici </a></p>
                            
                <p>Vous pouvez aussi nous contacter au 06.36.40.56.58.</p>
            </div>
          
        </div>    
</section>