import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-francais-fle',
  templateUrl: './francais-fle.component.html',
  styleUrls: ['./francais-fle.component.css']
})
export class FrancaisFleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
