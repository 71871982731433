<section class="site-section">
        <div class="container">
            <div class="col-lg-8 offset-lg-2 col-sm-12">
                
                <p><img class="img-fluid" src="../assets/pages/sciences-islam.svg" alt="Image placeholder"></p>
                
                <h3>Sciences de l’islam</h3>
                <p class="text-justify mb-1">METHODE PLUS vous propose des cours d’études islamiques pour apprendre et faire découvrir l’Islam aux adultes non arabophones. </p>
                 <p class="text-justify ">Cette formation a pour but de permettre aux étudiants de trouver des réponses à leurs interrogations et de leur transmettre la méthodologie et les capacités
                      d’analyse pour une bonne compréhension de l’islam et d’acquérir une connaissance approfondie et argumentée des différentes disciplines religieuses
                      musulmanes</p>

                <h3>Créneaux horaires proposés</h3>
                <p class="text-justify">2 heures par semaine, dispensés en français chaque samedi ou dimanche matin de <strong>10h à 12h.</strong></p>
    
                
                <h3>Programme</h3>	
                <p class="mb-1">Le programme s’articule autour de trois axes :</p>			
                <h6>La croyance</h6>
                <ul class="mb-4">
                    <li>Le Créateur et les attributs divins</li>
                    <li>Les Prophètes</li>				
                    <li>Les Anges</li>
                    <li>Le jour du jugement dernier</li>
                    <li>La prédestination</li>
                    
                </ul>
                
                <h6>La jurisprudence des actes cultuels</h6>
                <ul class="mb-4">
                    <li>La prière</li>
                    <li>Le jeûne</li>				
                    <li>La zakat</li>
                    <li>Le pèlerinage</li>
                </ul>			
    
                <h6>Culture générale sur l’Islam</h6>
                <ul>
                    <li>Introduction à la purification de l’âme </li>
                    <li>Introduction à la tradition prophétique</li>				
                    <li>Civilisation musulmane</li>				

                </ul>						
                
                <h3>Modalités d’inscription</h3>			
                
                <ul>
                    <li>Avoir 14 ans et plus.</li>
                    <li>Fiche d’inscription dûment remplie et signée</li>				
                    <li>Une photo d'identité récente</li>
                    <li>S’acquitter des frais de scolarité (possibilité de payement en quatre fois)</li>
                    <li>Acheter les livres qui serviront à l’enseignement.</li>
                
                </ul>
                            
                <h3>Créneaux horaires proposés</h3>
                <p class="text-justify">chaque samedi ou dimanche matin, de <strong>10h à 12h.</strong></p>
                
                <h3>Tarifs</h3>
                <p class="mb-5"><strong>350 euros /an</strong> (Possibilité de payement en trois fois)</p>
                
                <p> <strong>S’INSCRIRE : </strong><a class="btn btn-md btn-primary" href="https://docs.google.com/forms/d/1LFd8VTYOE61TiNdcaJU91VhYcv5EKm73R9q2yxtX_w8/edit" target="_blank"> Cliquer ici </a></p>
               
                
            </div>
            
        </div>
    
    
    </section>