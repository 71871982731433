
<section class="site-section">
        <div class="container">
            <div class="col-lg-8 offset-lg-2 col-sm-12">
                
                <p><img class="img-fluid" src="../assets/pages/arabe-enfants.svg" alt="Image placeholder"></p>
                
                <h3>Arabe pour enfants</h3>
                <p class="text-justify "><strong>METHODE PLUS</strong> met à votre disposition une structure moderne et compétente dont l'objectif est d’acquérir des bases essentielles de la langue arabe (lecture,
                        écriture et expression).
                </p>
              
            
                
                <p class="mb-2 text-justify">Pour réaliser ces objectifs, METHODE PLUS met en place les moyens suivants :</p>		
                    <p class="mb-2">1) Une équipe d’enseignants spécialisés et qualifiés dans l’enseignement de la langue arabe et de l’éducation musulmane .</p>
                    <p class="mb-2">2) Des méthodes pédagogiques personnalisées . </p>				
                    <p class="mb-2">3) Un programme riche et diversifié .</p>
                    <p class="mb-2">4) Des locaux spacieux, lumineux, et surtout adaptés à l'accueil des jeunes enfants avec des équipements ludiques et du mobilier fonctionnel et sécurisé .</p>
                    <p class="mb-2">5) Cours de consolidation à distance en semaine . </p>
                    <p class="mb-2">6) Activités culturelles et sorties éducatives.</p>
                
                            
                <h3>Créneaux horaires proposés</h3>
                <p>Chaque <strong>Samedi</strong> et <strong>dimanche</strong> de <strong>09h30</strong> à <strong>12h</strong> ou de <strong>13h</strong> à <strong>15h30</strong></p>
                <p>2h30 / semaine</p>
                <p>30 semaines de cours (75h)</p>
                
                <h3>Modalités d’inscription</h3>
                <p class="mb-1">L’inscription est définitive lorsque le dossier est complet :</p>
                <ul>
                    <li>Avoir 4 ans et plus.</li>
                    <li>Fiche d’inscription dument remplie et signée </li>
                    <li>Une photo d’identité récente de l’élève.</li>
                    <li>1 enveloppes timbrées sans le libellé </li>
                    <li>Une attestation d’assurance responsabilité civile</li>
                    <li>S’acquitter des frais de scolarité (possibilité de payement en trois fois)</li>
                    <li>Acheter les livres qui serviront à l’enseignement.</li>
                </ul>
            
                <p class="mb-2">Une évaluation est prévue lors de l’inscription afin de définir le niveau de l’élève.</p>
    <p > <strong>S’INSCRIRE : </strong><a class="btn btn-md btn-primary" href="https://docs.google.com/forms/d/1oRlksdU0TPW8voSTuitLuUFWRXt1cRSVnWSqY3LvZ3s/edit?usp=drive_web" target="_blank"> Cliquer ici </a></p>
                
            </div>
        </div>
    
    
    </section>
