<section class="site-section">
        <div class="container">
            <div class="col-lg-8 offset-lg-2 col-sm-12">
                
                <p><img class="img-fluid" src="../assets/pages/arabe-adultes.svg" alt="Image placeholder"></p>
                
                <h3> Arabe pour adultes</h3>
                <p class="text-justify ">METHODE PLUS propose un enseignement de langue arabe pour les adultes permettant la maîtrise de l’arabe littéraire (écrit et oral). </p>                 
                <p class="text-justify ">Le but est de faciliter la compréhension de la langue arabe et d’apporter une connaissance plus riche sur la civilisation arabo-musulmane.
                        La formation dure 3 ans. </p>
              
                <h3>Créneaux horaires proposés</h3>
                <p class="text-justify">Soit la <strong> semaine en fin de journée, </strong> soit le <strong> week-end.</strong> </p>
                <p>2h / semaine</p>
                <p>30 semaines de cours (60h)</p>
                
                                
                <h3>Modalités d’inscription</h3>
                <ul >
                    <li>Avoir 14 ans et plus.</li>
                    <li>Fiche d’inscription dûment remplie et signée </li>
                    <li>Une photo d'identité récente</li>
                    <li>Une enveloppe timbrée sans le libellé</li>
                    <li>S’acquitter des frais de scolarité (possibilité de payement en quatre fois)</li>
                    <li>Acheter les livres qui serviront à l’enseignement.</li>
                </ul>
                <p>L’inscription est définitive lorsque le dossier est complet.</p>
    
                <p class="text-justify ">Une évaluation est prévue lors de l’inscription afin de définir votre niveau</p>
                <p>A la fin de vos études, le centre METHODE PLUS, vous soumettrez à un examen de synthèse dont les questions portent sur l’ensemble du programme et vous
                    délivre un diplôme de fin d’étude.</p>
                <p class="mb-2">Notre équipe d'experts saura vous accompagner et vous guider pour atteindre votre objectif.</p>
                <p> <strong>S’INSCRIRE : </strong><a class="btn btn-md btn-primary" href="https://docs.google.com/forms/d/1zaa4vylpowxnCP68x9Xp-ulZdLeEHYL6luzK4SKzSUE/edit" target="_blank" > Cliquer ici </a></p>
                            
                
                
            </div>
          
        </div>
    
    
    </section>
