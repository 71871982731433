<!--carousel  
  -----------------------------------------------------------------------
 -->    
 <div id="myCarousel" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">
        <div class=" carousel-item active">
            <div class="container">
                <div class="carousel-caption text-left col-md-8 col-sm-12 col-xs-12">
                
                  
                    <p><a class="btn btn-md btn-primary btn_big" href="https://docs.google.com/forms/d/1oRlksdU0TPW8voSTuitLuUFWRXt1cRSVnWSqY3LvZ3s/edit?usp=drive_web" target="_blank">S'inscrire</a></p>
                </div>
            </div>
        </div>
        <div class="carousel-item">
            <div class="container">
                <div class="carousel-caption text-left col-md-8 col-sm-10 col-xs-10">
                    <h2 >Formulaire de demande d'informations    </h2>
                    
                    <p><a class="btn btn-md btn-primary"  href="https://docs.google.com/forms/d/1sYpHPz0rWkBmFZkWEBQlUFGrsxaOFWE0SXX2d3DxIIA/edit" target="_blank">S'informer</a></p>
                </div>
            </div>
        </div>              
    </div>

    <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
    <span class="fa fa-angle-left" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
    </a>
	
    <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
    <span class="fa fa-angle-right"></span>
    <span class="sr-only">Next</span>
    </a>
</div>
              
<!--About  
  -----------------------------------------------------------------------
 -->
 <section class="about-area " >
	<div class="container ">
        <div class="main_title text-center">
            <h2>Qui sommes nous</h2>
        </div>
		<div class="row">  
			<div class="col-lg-8 offset-lg-2 col-md-12 col-sm-12 " >
                <p> <img src="../assets/section/qui_somme_nous.svg" class="img-fluid"></p>
                <p class="text-justify "><strong>METHODE PLUS</strong> est une association à but non lucratif et basé à Nantes. C’est un centre spécialisé dans l’enseignement des langues
                    vivantes et du soutien scolaire.
                <p class="text-justify mb-1"> Nous sommes des professionnels de l’enseignement à la recherche du meilleur pour nos élèves.</p>
                <p class="text-justify ">Notre principale préoccupation est la réussite et le bien-être de nos élèves.</p>
            </div>
		</div>
	</div>
</section>
  
<!--  features area
  -----------------------------------------------------------------------
 -->
             
<section class="feature_area">
    <div class="container">       
        <div class="row feature_inner">    
		
            <div class="col-lg-3 col-md-6 mb-4 ">   
                <div class="feature_item " >
                    <div class='imgs'><a ><img  class="img-fluid mx-auto d-block"   src="../assets/section2/qualité.svg" ></a>
					</div>                           
                    <div class="content">
                            <h2 class="text-center">Expérience pédagogique</h2>
                            <p class="text-center">Nous avons l’habitude d’enseigner tout au long de l’année aux élèves et nous connaissons bien vos attentes et besoins</p>
                    </div>
                </div> 
            </div>
             
            <div class="col-lg-3 col-md-6 mb-4">
                <div class="feature_item ">
                    <div class='imgs'><a ><img class="img-fluid mx-auto d-block"  src="../assets/section2/méthode.svg" ></a>
					</div>
                    <div class="content">
                            <h2 class="text-center">Souci d’efficacité</h2>
                            <p class="text-center">METHODE PLUS a un objectif d’augmenter sensiblement les chances de réussite des élèves qui nous font confiance</p>
                    </div>
                </div>
            </div>
                            
            <div class="col-lg-3 col-md-6 mb-4">
                <div class="feature_item ">
                    <div class='imgs'><a ><img class="img-fluid  mx-auto d-block"    src="../assets/section2/espace.svg" ></a>
					</div>
                    <div class="content">
                            <h2 class="text-center">Espace Éducatif</h2>
                            <p class=" text-center">Des locaux spacieux, lumineux, et surtout adaptés à l'accueil des jeunes enfants avec des équipements ludiques</p>
                    </div>
                </div>
            </div>
             
            <div class="col-lg-3 col-md-6 mb-4">   
                <div class="feature_item ">
                    <div class='imgs'><a ><img class="img-fluid  mx-auto d-block"   src="../assets/section2/suivi.svg" ></a>
					</div>
                    <div class="content">
                            <h2 class="text-center">Suivi</h2>
                            <p class="text-center">Tout au long de votre formation, vous êtes suivi(e) par une équipe de professeurs accompagnateurs</p>
                    </div>
                </div>
            </div>
        </div>
    </div>                                 
</section>

 
<section class="gallery_area">
	<div class="container">
	
		<div class="row" >
			<div class="col-lg-4 col-md-4 col-xs-6">
				<div class="h_gallery_item">
					
					<div class="img_item">
						<img class="img-fluid mx-auto d-block" src="../assets/section/cours-enfants.jpg">
					</div>
					
					<div class="item_text">
						<h5 class="text-center">Cours d'arabe pour enfants</h5>
					</div> 
					
					<div class="btn_2">
						<button class="btn btn-md btn-primary mx-auto d-block" routerLink="/arabe-pour-enfants" role="button">Voir plus</button>
					</div> 
			
				</div>
			</div>
    
			<div class="col-lg-4 col-md-4 col-xs-6">  
				<div class="h_gallery_item">
					
					<div class="img_item"> 
						<img class="img-fluid mx-auto d-block" src="../assets/section/cours-adultes.jpg">
					</div>
					
					<div class="item_text">
						<h5 class="text-center">Cours d'arabe pour adultes</h5>
					</div>
        
					<div class="btn_2">
						<button class="btn btn-md btn-primary mx-auto d-block" routerLink="/arabe-pour-adultes"  role="button">Voir plus</button>
					</div> 
				
				</div>
			</div>
			
			<div class="col-lg-4 col-md-4 col-xs-6">
				<div class="h_gallery_item"> 
					
					<div class="img_item"> 
						<img class="img-fluid mx-auto d-block" src="../assets/section/soutien-scolaire.jpg">
					</div>
					
					<div class="item_text">
						<h5 class="text-center">Soutien scolaire</h5>
					</div>
					
					<div class="btn_2">
						<button class="btn btn-md btn-primary mx-auto d-block" routerLink="/soutien-scolaire" role="button">Voir plus</button>
					</div>
					
				</div>
			</div>
		</div>
	</div>
 </section>

   