import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-soutien-scolaire',
  templateUrl: './soutien-scolaire.component.html',
  styleUrls: ['./soutien-scolaire.component.css']
})
export class SoutienScolaireComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
