<!-- header part start -->
<header class="main_menu home_menu">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12">
                <nav class="navbar navbar-expand-lg navbar-light">
                    <a class="navbar-brand" href="https://www.methodeplus.net/"> <img src="..\assets\logo\logo3.svg" alt="logo"> </a>
                    <button class="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#navbarSupportedContent"  aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                  
                    <div class="collapse navbar-collapse main-menu-item justify-content-end"
                        id="navbarSupportedContent">
                        <ul class="navbar-nav align-items-center">
                            <li class="nav-item  ">
                                <a class="nav-link" routerLink="/accueil">Accueil</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" routerLink="/qui-sommes-nous">Qui sommes nous</a>
                            </li>
                            <li class="nav-item dropdown">
                              <a class="nav-link dropdown-toggle"  id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Langues Vivantes                              </a>
                              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                  <li class="dropdown-submenu dropdown">
                                      <a class="dropdown-item dropdown-toggle"  id="navbarDropdown1" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Arabe</a>
                                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown1">
                                            <li><a class="nav-link" routerLink="/arabe-pour-enfants">Pour Enfants</a></li>
                                            <li><a class="nav-link" routerLink="/arabe-pour-adultes">Pour Adultes</a></li>
                                        </ul>
                                  </li>
                            <li >
                                <a class="dropdown-item" routerLink="/anglais">Anglais</a>
                            </li>
                            <li >
                                <a class="dropdown-item" routerLink="/francais-fle">Français langue etrangère</a>
                            </li>
                              
                              </ul>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" routerLink="/soutien-scolaire">Soutien Scolaire</a>
                            </li>
<!--                             
                            <li class="nav-item">
                                <a class="nav-link" routerLink="/sciences-islam">Sciences de l'islam</a>
                            </li>     
                                                   -->
                             
                        </ul>
                    </div>
                </nav>    
            </div>
        </div>
    </div>
  </header>
  <!-- Header part end -->